<template>
  <div class="content">
    <div class="cinema">
      <div class="cinema_btn" @click="goCinema">去购票</div>
      <div class="DomesticStyle" @click="goDomesticDetail">看电影，得现金，拿返佣 ></div>
    </div>
  </div>
</template>

<script>
import { getMovieUrl } from '@/services/comeOn.js'
import { isWeChat } from '@/utils/common/validate'
import { wxConfigInit } from '@/utils/wx-api'

export default {
  name: 'MovieTickets',
  data() {
    return {
      mid: '',
      sourceSign: '',
      sharer: '',
      roomId: '',
      mobile: ''
    }
  },
  created() {
    // this.mid =
    //     window.localStorage.getItem("uid") || this.getQueryVariable("mid");
    // if (this.$route.query.env === "99") {
    //     if (!this.$route.query.mid) {
    //         this.$router.push({
    //             path: "/login",
    //             query: {
    //                 codeNumber: this.$route.query.codeNumber,
    //             },
    //         });
    //     }
    // } else {
    //     if (this.mid == false) {
    //         this.$router.push({
    //             path: "/login",
    //             query: {
    //                 codeNumber: this.$route.query.codeNumber,
    //             },
    //         });
    //     }
    // }
    this.mid = window.localStorage.getItem('uid') || this.$route.query.mid

    if (this.mid === undefined || this.mid === false) {
      this.$router.push({
        path: '/login',
        query: {
          codeNumber: this.$route.query.codeNumber
        }
      })
      return
    }
    // 渠道编码
    this.sourceSign = this.getQueryVariable('sourceSign')
      ? this.getQueryVariable('sourceSign')
      : '1FD4T6FG'
    // 分享者id
    this.sharer = this.getQueryVariable('codeNumber')
      ? this.getQueryVariable('codeNumber')
      : ''
    // 直订社群分享参数
    this.roomId = ''
    this.mobile =
      window.localStorage.getItem('mobile') ||
      JSON.parse(window.localStorage.getItem('userInfo')).mobile
    // 分享配置
    this.configShare()
  },
  methods: {
    configShare() {
      const shareInfo = {
        title: '悦淘看电影返现金，我已领取现金红包', // 分享标题
        desc: '我在悦淘有发现，绝对适合你，速来围观～', // 分享描述
        link:
          'https://web.yuetao.vip/MovieTickets?codeNumber=' +
          window.localStorage.getItem('recode'), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl:
          'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-27/20/yuelvhui2FKUWhpGvq1606481972.png' // 分享图标
      }
      wxConfigInit(shareInfo)
      // this.$store.dispatch('wxConfigInit', shareInfo)
    },
    // 去购票
    goCinema() {
      // 判断微信浏览器直接跳转3方，APP跳转跳自己H5
      if (!isWeChat()) {
        this.$router.push({
          path: '/goMovie',
          query: {
            mid: this.$route.query.mid
          }
        })
      } else {
        const body = {
          mid: this.mid,
          sourceSign: this.sourceSign,
          sharer: this.sharer,
          roomId: this.roomId
          // status: false, //获取测试地址（不传取正式地址）
        }
        getMovieUrl(body).then((res) => {
          if (Number(res.code) === 200) {
            window.location.replace(res.data.url)
          }
        })
      }
    },

    // url数据
    getQueryVariable(variable) {
      const query = window.location.search.substring(1)
      const vars = query.split('&')
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        if (pair[0] === variable) {
          return pair[1]
        }
      }
      return false
    },
    // 去分佣详情
    goDomesticDetail() {
      this.$router.push({
        path: '/FilmDomesticDetail'
      })
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  background-color: #f8f8f8;
  min-height: 100vh;

  .cinema {
    background-image: url("~@/assets/images/cinema.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100vh;
    position: relative;
  }

  .cinema_btn {
    position: absolute;
    left: 50%;
    bottom: 15%;
    width: 80%;
    margin-left: -40%;
    background-image: linear-gradient(#ffd49d, #fef0c2);
    color: #e33f44;
    border-radius: 7px;
    font-size: 18px;
    line-height: 43px;
  }

  .DomesticStyle {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 44px;
    background-color: #e33f44;
    text-align: center;
    line-height: 44px;
    color: #fff;
  }
}
</style>
